.chatbox {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 75vh;
  background-color: #fafafa;
  border-radius: 5px;
}

.past-messages {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1em;
  margin: 0;
  border-radius: 5px;
  border-bottom-left-radius: 0;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  resize: vertical;
  overflow: auto;
}

.message {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin: 0.5em 0;
  border-radius: 5px;
}

.send-message-input {
  display: flex;
  flex-grow: 0;
}

.send-box {
  flex: 3;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom-left-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  padding: 0.5em;
  font-size: 0.9em;
}

.send-button {
  border: 1px solid black;
  background-color: rgba(0, 0, 0, 0.05);
}

.message-time {
  width: 10%;
  text-align: right;
  font-size: 0.7em;
  color: rgba(0, 0, 0, 0.5);
  padding: 0.5em;
}

.message-sender {
  width: 15%;
  text-align: left;
  font-size: 0.7em;
  color: rgba(0, 0, 0, 0.7);
  padding: 0.5em;
}

.message-text {
  width: 80%;
  padding-left: 0.5em;
}

.message-text[data-type="draft"] {
  color: rgba(0, 0, 0, 0.7);
  font-weight: bold;
}
