div .dropdown-box {
  display: flex;
}

.dropdown-text {
  color: lightgray;
}

#teamsDropdown::after {
  color: lightgray;
}

.team-name {
  font-weight: bold;
}

.league-name {
  color: black;
  text-decoration: none;
  padding: 0.2em;
}

.league-name:hover {
  background-color: black;
  color: white;
  border-radius: 0.2em;
  text-shadow: 0.1em 0.1em black;
}
