.title-text {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.available-players {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-height: 74vh;
  background-color: #fafafa;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  padding: 10px;
}

.player-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  padding: 0.5em;
  overflow: auto;
}

.selectable-player {
  border: 1px solid black;
  padding: 1em;
  background-color: #fafafa;
  width: 100%;
  height: 100%;
  margin: 0.5em 0;
  border-radius: 5px;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.selectable-player:not(.selected):hover {
  background-color: #f0f0f0;
}

.selectable-player:not(.selected):active {
  background-color: #e0e0e0;
}

.selected {
  background-color: hsl(0, 0%, 66%);
  color: white;
}

.selected:hover {
  background-color: hsl(0, 0%, 50%);
}

.player-row {
  display: grid;
  grid-template-columns: 3em 35% repeat(3, 1fr);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}

.player-name {
  flex: 1 1 30%;
  font-size: 1.2rem;
  font-weight: bold;
}

.header-row {
  display: grid;
  grid-template-columns: calc(35% + 3em) repeat(3, 1fr);
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 1em;
}

.header-col {
  font-weight: bold;
  text-align: center;
  padding: 0.25em;
}

.header-col:first-of-type {
  flex: 1 1 40%;
}

.player-row > :not(.player-name):not(.team-logo-bubble) {
  flex: 1 1 20%;
  display: flex;
  justify-content: center;
}
