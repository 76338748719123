:root {
  --trade-pending: hsla(46, 100%, 50%, 0.3);
  --trade-accepted: hsla(83, 33%, 81%, 0.5);
  --trade-rejected: hsla(343, 100%, 39%, 0.3);
}

.trade-container {
  display: flex;
  flex-direction: column;
}

.team-trade-info {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0.5em;
  margin-left: 2em;
}

.trade-row {
  display: flex;
  align-items: center;
  padding: 0.375em 0.75em;
  transition: background-color 0.2s ease-out;
  background-color: hsla(0, 0%, 0%, 0.05);
}

@media screen and (max-width: 768px) {
  .trade-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .trade-icon {
    align-self: center;
  }

  .player-row {
    justify-content: space-between;
  }
}

.trade-row:hover {
  box-shadow: 0.2em 0.2em 0.5em hsla(0, 0%, 0%, 0.1);
}

.trade-row:last-of-type {
  margin-bottom: 1em;
}

.player-row {
  display: flex;
  align-items: center;
}

.player-name-row {
  display: flex;
  width: 60%;
  align-items: baseline;
  justify-content: space-between;
}

.row-arrow {
  cursor: pointer;
  height: 1.5em;
  width: 1.5em;
}

.trade-icon {
  height: 1.5em;
  width: 1.5em;
}

.accepted {
  background: linear-gradient(45deg, var(--trade-accepted), white);
}

.pending {
  background: linear-gradient(45deg, var(--trade-pending), white);
}

.rejected {
  background: linear-gradient(45deg, var(--trade-rejected), white);
}

.trade-row + .trade-row {
  margin-top: 1em;
}

@media screen and (max-width: 768px) {
  .team-trade-info {
    margin-left: 0;
  }
}

.accept-button {
  font-size: 1.05em;
  padding: 0.375em 0.75em;
  background-color: hsl(81, 50%, 50%);
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.2s ease-out;
}

.accept-button:hover {
  background-color: hsla(81, 50%, 50%, 0.5);
}

.accept-button:active {
  background-color: hsla(81, 50%, 50%, 0.7);
}

.reject-button {
  font-size: 1.05em;
  padding: 0.375em 0.75em;
  background-color: hsl(343, 50%, 39%);
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.2s ease-out;
  margin-top: 1em;
}

.reject-button:hover {
  background-color: hsla(343, 50%, 39%, 0.5);
}

.reject-button:active {
  background-color: hsla(343, 50%, 39%, 0.7);
}

.cancel-button {
  font-size: 1.05em;
  padding: 0.375em 0.75em;
  border: 2px solid lightgray;
  border-radius: 5px;
  background-color: transparent;
  color: hsl(0, 81%, 48%);
  transition: background-color 0.2s ease-out;
}

.cancel-button:hover {
  background-color: hsl(343, 100%, 39%, 0.5);
  color: white;
}

.cancel-button:active {
  background-color: hsl(343, 100%, 39%, 0.7);
  color: white;
}

.position-text {
  font-size: 0.8em;
  color: darkgray;
}

.button-row {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .button-row {
    flex-direction: row;
  }

  .button-row button + button {
    margin-top: 0;
    margin-left: 1em;
  }

  .position-text {
    align-self: center;
  }
}
