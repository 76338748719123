.toggle-list {
  margin: 0;
  margin-top: 1em;
  padding: 0;
  display: inline-flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none;
  /*border-bottom: 2px solid black;*/
}

.selectable-item {
  transition: all 0.2s;
  border: 0;
  padding: 0.5em 1em;
  background-color: rgba(112, 128, 144, 0.1);
  text-transform: uppercase;
}

.selectable-item:hover {
  background-color: rgba(112, 128, 144, 0.2);
}

.selected-item {
  background-color: rgba(112, 128, 144, 0.2);
  box-shadow: inset 0 -2px 0 0 black;
}
