:root {
  --qb-color-primary: hsla(37, 82%, 61%, 0.7);
  --rb-color-primary: hsla(351, 61%, 34%, 0.7);
  --wr-color-primary: hsla(188, 100%, 20%, 0.7);
  --te-color-primary: hsla(207, 49%, 37%, 0.7);
  --k-color-primary: hsla(200, 100%, 18%, 0.7);
}

.pick-container {
  max-height: 80vh;
  font-size: 0.95rem;
  overflow-y: scroll;
  padding: 0.5em;
  border: 1px solid #ccc;
}

.pick-grid {
  display: grid;
  row-gap: 1em;
  padding: 0.5em 1.5em;
}

.pick-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 15em;
  height: 10em;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0.5em;
  margin: 0.5em;
  cursor: pointer;
}

.pick-num {
  font-size: 1.1rem;
  font-weight: bold;
}

.box-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  width: 100%;
}

.box-body {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 100%;
  font-weight: bold;
}

.pick-body-row {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  font-size: 1.1rem;
}

.position-text {
  font-size: 0.9rem;
  color: white;
}

.pick-box[data-position="QB"] {
  background-color: var(--qb-color-primary);
  color: black;
}

.pick-box[data-position="QB"] > .box-header {
  border-bottom-color: black;
}

.pick-box[data-position="QB"] .position-text {
  color: hsl(0, 0%, 2%);
}

.pick-box[data-position="RB"] {
  background-color: var(--rb-color-primary);
  color: white;
}

.pick-box[data-position="WR"] {
  background-color: var(--wr-color-primary);
  color: white;
}

.pick-box[data-position="TE"] {
  background-color: var(--te-color-primary);
  color: white;
}

.pick-box[data-position="K"] {
  background-color: var(--k-color-primary);
  color: white;
}

.cur-pick {
  box-shadow: 0 0 5px rgba(81, 203, 238, 1);
}
