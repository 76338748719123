.draft-header {
  display: flex;
  height: 6em;
  align-items: center;
  border-bottom: 2px solid black;
}

.draft-pick-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-left: 1em;
}

.draft-pick-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 2em;
  width: 13em;
}

.pick-row {
  display: flex;
  justify-content: space-between;
}
