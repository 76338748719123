.roster-container {
  height: 80vh;
  overflow: scroll;
}

.current-roster-box {
  background-color: hsl(0, 0%, 96%);
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 10px;
}

.rostered-player-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}

.rostered-player-row:not(:last-of-type) {
  border-bottom: 1px solid hsl(0, 0%, 75%);
}

.rostered-player-position {
  font-weight: bold;
}
