:root {
  box-sizing: border-box;
}

.padded-container {
  padding: 0 3em;
}

#first-background {
  background-color: yellowgreen;
}

#second-background {
  background-color: silver;
}

#third-background {
  background-color: orange;
}

.subtitle {
  font-size: medium;
  color: gray;
}

#inline-button {
  padding: 0;
  border-radius: 0;
  color: red;
  border: none;
}

.small-image {
  width: 4rem;
  height: 4rem;
}

.black-link {
  color: black;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.image-fit {
  max-width: 20%;
  height: auto;
}

.image-fit-height {
  width: 120px;
  max-height: 120px;
}

.thumbnail-image {
  width: 50px;
  max-height: 50px;
}

.hide-cells {
  border-collapse: separate;
  empty-cells: hide;
}

.table-width {
  width: auto !important;
}

.centered-popup {
  position: fixed;
  left: 50%;
  top: 0%;
  transform: translateX(-50%);
  z-index: 9999;
}

.spinning-loader {
  height: 5rem;
  border: 5px solid lightblue;
  border-left-color: darkblue;
  border-radius: 50%;
  background: transparent;
  animation-name: rotate-s-loader;
  animation-iteration-count: infinite;
  animation-duration: 1s;
  animation-timing-function: linear;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 5rem;
  z-index: 9999;
}

@keyframes rotate-s-loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.centered-td {
  text-align: center;
  vertical-align: middle;
}

.sticky-th {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
  font-size: 0.75em;
}

.table-wrapper {
  overflow-x: auto;
}

.overflow-y-wrapper {
  overflow-y: visible;
}

.team-table-wrapper {
  max-width: 90vw;
}

.left-table-wrapper {
  overflow-x: auto;
  max-width: 90vw;
  max-height: 85vh;
}

.centered-scrollable-table {
  margin: 0 auto;
  max-width: max-content;
}

.left-scrollable-table {
  margin: 0;
}

.sticky-col {
  position: sticky;
  z-index: 1;
}

.sticky-td {
  left: -15px;
  width: 100px;
}

.time-display {
  color: green;
}

.card-size {
  max-width: 20%;
}

.max-height-table {
  display: inline-block;
  max-height: 70vh;
  overflow-y: scroll;
}

.spaced-span {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
}

.logo-image {
  max-width: 2rem;
  max-height: 2rem;
  margin-right: 1em;
}

.player-played {
  background-color: #f0f0f0;
  opacity: 0.7;
}