#first-background {
  background-color: yellowgreen;
}

#second-background {
  background-color: silver;
}

#third-background {
  background-color: orange;
}
